
































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SchemaExampleOnly extends Vue {
	@Prop({ required: false, type: Object }) readonly responses!: Record<
		string,
		string
	>;

	private types = ["schema", "example"];
	private selectedType = "schema";
	private selectedStatus: string | null = null;

	upperCase(type: string) {
		return type.charAt(0).toUpperCase() + type.slice(1);
	}
}
